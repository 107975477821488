// const firebase = require('firebase');
const http = require('http');
const https = require('https');
const qs = require('querystring');

export const inicializeFirebase = () => {
  // firebase.initializeApp({
  //     apiKey: 'AIzaSyBYoauaTC6fOdbcJWcJSlyg7C8pbSqfKx8',
  //     authDomain: 'ducwallet-dev.firebaseapp.com',
  //     databaseURL: 'https://ducwallet-dev.firebaseio.com',
  //     projectId: 'ducwallet-dev',
  //     storageBucket: '',
  //     messagingSenderId: '112337120259',
  //     appId: '1:112337120259:web:3c76453084c00753',
  // });
};

function registerUserInNotificationManager(userid, token) {
  let data = { userid: userid, token: token };
  let payload = qs.stringify(data);

  let [host, port] = process.env.REACT_APP_NOTIFICATION_MANAGER_HOST.split(':');

  const options = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Content-Length': Buffer.byteLength(payload),
    },
    hostname: host,
    method: 'POST',
    path: '/api/firebase_user',
  };
  if (port)
    options.port = port;

  let request;
  try {
    if (process.env.REACT_APP_NOTIFICATION_MANAGER_PROTOCOL &&
            process.env.REACT_APP_NOTIFICATION_MANAGER_PROTOCOL==='http')
      request = http.request(options, (res) => {
        res.on('data', function (data) {
          if (res.statusCode!==200) {
            console.log(res.statusCode);
          }

          if (data) {
            data = JSON.parse(data);
            //console.log('Response:', data);
          }
        });
      });
    else
      request = https.request(options, (res) => {
        res.on('data', function (data) {
          if (res.statusCode!==200) {
            // throw new Error('Not OK (non-200 status code received) ' + res.statusCode);
            console.log(res.statusCode);
          }

          if (data) {
            data = JSON.parse(data);

            //console.log('Response:', data);
          }
        });
      });

    request.on('error', function (e) {
      console.error(e.message);
    });

    request.write(payload);
    request.end();
  } catch (error) {
    console.error('ERROR:', error);
  }
}

// export const refreshFirebaseToken = () => {
//     const messaging = firebase.messaging();
//     messaging.getToken().then((currentToken) => {
//         if (currentToken) {
//             console.log(currentToken);
//         } else {
//             // Show permission request.
//             console.log('No Instance ID token available. Request permission to generate one.');
//             // Show permission UI.
//             // updateUIForPushPermissionRequired();
//             // setTokenSentToServer(false);
//         }
//     }).catch((err) => {
//         console.log('An error occurred while retrieving token. ', err);
//         // setTokenSentToServer(false);
//     });
// };

export const askForPermissionToReceiveNotifications = async (userid) => {
  try {
    // const messaging = firebase.messaging();
    // await messaging.requestPermission();
    // const token = await messaging.getToken();
    const token = '';
    //console.log('USER TOKEN', token);
    registerUserInNotificationManager(userid, token);
  } catch (error) {
    console.error(error);
  }
};
