// import React from 'react';
// import * as serviceWorker from './serviceWorker';
import 'react-toastify/dist/ReactToastify.min.css';
import './i18n';
import {inicializeFirebase} from './Utils/push-notifications/push-notification';

// Source: https://youtu.be/j-WcyAjVceM
async function renderAsync() {
  const [
    React,
    {hydrate},
    {default: App},
  ] = await Promise.all([
    import('react'),
    import('react-dom'),
    import('./App'),
    import('./RouteAuth0')
  ]);

  // Inject react app components into App's Shell
  hydrate(<App/>, document.getElementById('root'));
}

renderAsync().catch((error) => {
  console.log('error on renderAsync', error);
});

inicializeFirebase();

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('../firebase-messaging-sw.js')
    .then(function (registration) {
      console.log('---------------------------------------------------Registration successful----------------, scope is:', registration.scope);
    }).catch(function (err) {
      console.log('---------------------------------Service worker registration failed, error: -------------------------------------------', err);
    });
}
