export const fr = {
  client: {
    landing: {
      menuItems: {
        home: 'Accueil',
        about: 'Environ',
        appFeatures: 'Caractéristiques App',
        download: 'Télécharger',
        faqs: 'FAQs',
        contactUs: 'Contactez nous',
        login: 'S\'identifier',
      },
      downloadLinks: {
        googlePlay: 'GOOGLE PLAY',
        googlePlayDescription: 'Obtenez-le sur le',
        appleStore: 'APPLE STORE',
        appleStoreDescription: 'Disponible sur le',
      },
      homeSection: {
        titleLine1: 'DUC App.',
        titleLine2: 'Plateforme de',
        titleLine3: 'paiement de bout en bout',
        description: 'Un système de bout en bout riche en fonctionnalités, sans Fintech, pour les paiements transfrontaliers, locaux et les devises à lancer début 2020',
      },
      featureSection: {
        title: 'Plateforme de paiement de bout en bout',
        description: 'Les unités de monnaie décentralisées (DUC) sont des versions numériques exactes et stables de l\'argent fiduciaire réel, idéales pour les opérations bancaires quotidiennes, l\'inclusion financière, la géolocalisation et les paiements de messagerie sociale avec règlement en direct et visuel.',
        featureOptions: {
          '0': {
            title: 'Payez et soyez payé en quelques secondes',
            description: 'Payer des amis, des factures professionnelles OU envoyer des fonds sur un compte bancaire EN UTILISANT la technologie blockchain SECURISEE et les contrats intelligents',
          },
          '1': {
            title: 'Demander des paiements et des fonds',
            description: 'Inscrivez-vous à un compte personnel ou professionnel pour demander le paiement de plans ou de services personnels rendus.',
          },
          '2': {
            title: 'Plusieurs sources de financement',
            description: 'Différentes options pour financer vos comptes de portefeuille: directement à partir d\'amis, de la famille, de cartes de crédit, de débit ou de comptes bancaires. Vos informations sont cryptées et conservées uniquement sur votre appareil.',
          },
          '3': {
            title: 'Recharge facile',
            description: 'Besoin d\'envoyer des recharges ou du temps d\'antenne Internet à une famille, un ami ou une entreprise? Vous pouvez facilement le faire avec notre choix Recharges!',
          },
          '4': {
            title: 'Devises Fiat réelles CAD, USD, EUROS ou tout autre',
            description: 'En quelques clics, convertissez vos fonds dans d\'autres devises disponibles, à des taux de change compétitifs.',
          },
          '5': {
            title: 'Plateforme de géosystème financière brevetée',
            description: 'Vous pouvez envoyer des fonds à vos amis et votre famille ou payer des factures et des factures dans votre région ou à travers le monde, en les localisant simplement sur notre carte interactive. Passez des appels téléphoniques, discutez en utilisant notre option de carte de géolocalisation unique.',
          },
        },
      },
      videoSection: {
        title: 'Une plateforme riche en fonctionnalités',
        description: 'DUC App est exactement ce que vous recherchiez pour une nouvelle application leader du secteur qui donne aux utilisateurs la possibilité de simplifier leur vie en quelques clics.',
      },
      downloadSection: {
        title: 'Obtenez l\'application maintenant',
        description: 'Plateforme facile à utiliser avec un large éventail de fonctionnalités créées dans le but de vous aider à vivre votre vie au maximum. Avec des options en temps réel et un accès illimité, c\'est une application que vous voudrez certainement télécharger.',
      },
      faqsSection: {
        title: 'FAQs',
        subTitle: 'L\'application leader de l\\\'industrie. Ici pour aider',
        items: {
          '0': {
            text: 'J\'ai vu d\'autres applications à des fins similaires. Qu\'est-ce qui rend le vôtre le meilleur?',
            description: 'En plus des fonctionnalités standard d\'envoi d\'argent et de réception de paiements en quelques secondes, vous pouvez désormais le faire à partir de notre option unique dans la carte. Et lorsque vous voyagez, l\'application DUC vous permet de savoir à l\'avance et libère les inquiétudes concernant le taux de change ou les frais supplémentaires lorsque vous payez en devises locales.',
          },
          '1': {
            text: 'À quelle fréquence y a-t-il des mises à jour de l\'application?',
            description: 'Comme pour toute autre application, des mises à jour sont effectuées de temps à autre. Parfois parce que votre propre appareil a été mis à niveau et d\'autres fois, parce que nous avons ajouté de nouvelles fonctionnalités ou services pour étendre vos options!!! Et cela est transparent pour vous.',
          },
          '2': {
            text: 'Tout cela sonne bien, mais que fait réellement cette appli?',
            description: 'Effectuez et recevez des paiements à partir d\'une variété d\'appareils (téléphone, ordinateur portable, tablette, ordinateur de bureau). Convertissez en plusieurs devises. Effectuez et demandez des paiements directement sur une carte. Recharger les téléphones (recharges). Communiquez avec les autres via la messagerie. Utilisez et partagez des cartes virtuelles. Encaissez l\'argent dans la devise de votre choix. À l\'échelle mondiale.',
          },
        },
      },
      aboutSection: {
        appElement: {
          title: 'Duc app',
        },
        downloadElement: {
          title: 'Télécharger',
          androidItem: 'Android',
          iosItem: 'IOs',
        },
        supportElement: {
          title: 'Soutien',
        },
        copyright: '© Copyright {{year}} DUC App Corporation. Tous les droits sont réservés.',
        terms: 'Conditions de services',
        privacy: 'Politique de confidentialité',
      },
      boardSection: {
        userElement: {
          title: 'Utilisateurs',
        },
        transactionsElement: {
          title: 'Transactions',
        },
        rechargesElement: {
          title: 'Recharges',
        },
      },
    },
    webapp: {
      newVersionConfirm: 'Actuelle: v{{latestVersion}}\tDernière: v{{latestVersion}}\nUne nouvelle version de cette application est disponible.\nVoulez-vous rafraîchir la page?',
    },
    admin: {
      toasts: {
        actions: {
          view: 'Voir',
        },
        alarms: {
          message: 'De nouvelles alarmes ({{ alarmCount }}) marquées comme HAUTE PRIORITÉ sont actuellement actives.',
        },
      },
    },
  },
};
