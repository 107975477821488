export const en = {
  client: {
    landing: {
      menuItems: {
        home: 'Home',
        about: 'About',
        appFeatures: 'App Features',
        download: 'Download',
        faqs: 'FAQs',
        contactUs: 'Contact us',
        login: 'Login',
      },
      downloadLinks: {
        googlePlay: 'GOOGLE PLAY',
        googlePlayDescription: 'Available on',
        appleStore: 'APPLE STORE',
        appleStoreDescription: 'Available on',
      },
      homeSection: {
        titleLine1: 'DUC App.',
        titleLine2: 'End-to-End Payment',
        titleLine3: 'Platform',
        description: 'A feature-rich fintech financial system, for cross-border, local payments & foreign exchange',
      },
      featureSection: {
        title: 'End-to-End Payment Platform',
        description: 'Decentralized Units of Currencies (DUC) are one-to-one, exact and stable digital versions of real, fiat money, ideal for everyday banking, financial inclusion, geolocation & social messaging payments with live & visual settlement.',
        featureOptions: {
          '0': {
            title: 'Pay & Get Paid in seconds',
            description: 'Pay persons, friends, business invoices or send funds to a bank account using secure blockchain & smart contracts technology.',
          },
          '1': {
            title: 'Request Payments and Funds',
            description: 'Register for a personal or business account to request payments for personal plans or services rendered.',
          },
          '2': {
            title: 'Multiple funding sources',
            description: 'Different options to fund your wallet accounts: directly from persons, friends, family, from credit/debit cards or bank accounts. Your information is encrypted and kept only on your device.',
          },
          '3': {
            title: 'Easy Pay Top-Ups',
            description: 'Need to send Top-Ups or internet airtime to a family, friend or business? You can easily do so with our Top-Ups option!',
          },
          '4': {
            title: 'Real Fiat Currencies CAD, USD, EUROS or any other',
            description: 'In a few clicks convert your funds to other available currencies, at competitive foreign exchange rates.',
          },
          '5': {
            title: 'Financial Patented Geosystem Platform',
            description: 'You can send funds to your friends and family or pay bills and invoices in your area or across the world, by simply locating them in our interactive map. Make phone calls, chat using our unique geolocation map option.',
          },
        },
      },
      videoSection: {
        title: 'A Feature-Rich Platform',
        description: 'DUC App is just what you’ve been looking for a new, industry-leading App that gives users the chance to simplify their lives with just a few clicks.',
      },
      downloadSection: {
        title: 'Get The App Now',
        description: 'Easy-to-use platform with a wide range of features created with an aim to help you live life to the fullest. With real-time options and unlimited access, this is one App you’ll definitely want to download.',
      },
      faqsSection: {
        title: 'FAQs',
        subTitle: 'The Industry-Leading App. Here to Help',
        items: {
          '0': {
            text: 'I’ve seen other Apps that serve similar purposes. What makes yours that useful?',
            description: 'In addition to the standard features of sending money and receive payments in seconds, now you will do it from our unique map option. And when you travel,  DUC App allows you to know ahead and releases the worries about the rate of exchanges or additional charges when you pay in local currencies.',
          },
          '1': {
            text: 'How often are there updates to the App?',
            description: 'As with any other App, updates are made from time to time. Some times because your own device has been upgraded and other times because we added new features or services to expand your options!!! And this is transparent to you.',
          },
          '2': {
            text: 'It all sounds great, but what does this App actually do?',
            description: 'Make and receive payments from a variety of devices (phone, tablet, laptop, desktop computer). Convert to multiple currencies. Make and request payments directly on a map. Recharge phones and air time (top-ups). Communicate with others via messaging. Use and share virtual cards. Cash-out the money in the currency of your preference. Worldwide.',
          },
        },
      },
      aboutSection: {
        appElement: {
          title: 'DUC App',
        },
        downloadElement: {
          title: 'Download',
          androidItem: 'Android',
          iosItem: 'IOs',
        },
        supportElement: {
          title: 'Support',
        },
        copyright: '© Copyright {{year}} D.U.C. App Corporation. All rights reserved.',
        terms: 'Terms of Services',
        privacy: 'Privacy Policy',
      },
      boardSection: {
        userElement: {
          title: 'Users',
        },
        transactionsElement: {
          title: 'Transactions',
        },
        rechargesElement: {
          title: 'Recharges',
        },
      },
    },
    webapp: {
      newVersionConfirm: 'Current: v{{currentVersion}}\tLatest: v{{latestVersion}}\nThere is a new version of this app available.\nDo you want to refresh the page?',
    },
    admin: {
      toasts: {
        actions: {
          view: 'View',
        },
        alarms: {
          message: 'There are new alarms ({{ alarmCount }}) marked as HIGH PRIORITY that are currently active.',
        },
      },
    },
  },
};
