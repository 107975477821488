export const es = {
  client: {
    landing: {
      menuItems: {
        home: 'Inicio',
        about: 'Acerca de',
        appFeatures: 'Características',
        download: 'Descarga',
        faqs: 'Ayuda',
        contactUs: 'Contáctenos',
        login: 'Iniciar sesión',
      },
      downloadLinks: {
        googlePlay: 'GOOGLE PLAY',
        googlePlayDescription: 'Disponible en',
        appleStore: 'APPLE STORE',
        appleStoreDescription: 'Disponible en ',
      },
      homeSection: {
        titleLine1: 'DUC App.',
        titleLine2: 'Plataforma de',
        titleLine3: 'Pago Integral',
        description: 'Un sistema de gestión monetaria global con múltiples funciones, basada en tecnología financiera, para pagos locales e internacionales y cambio de divisas. Disponible desde inicios de 2020',
      },
      featureSection: {
        title: 'Plataforma de Pago Integral',
        description: 'Las Unidades de Monedas Descentralizadas (DUC) son versiones digitales individuales, exactas y estables de dinero real fiduciario, ideales para la banca diaria, inclusión financiera, pagos utilizando geolocalización y mensajes sociales, con liquidación disponible en tiempo real.',
        featureOptions: {
          '0': {
            title: 'Pague y reciba pagos en segundos',
            description: 'Pague a personas, amigos y facturas comerciales o envíe fondos a una cuenta bancaria utilizando tecnología segura de blockchain y contratos inteligentes.',
          },
          '1': {
            title: 'Solicitar pagos y fondos monetarios',
            description: 'Regístrese para obtener una cuenta personal o comercial para solicitar pagos de planes personales o servicios prestados.',
          },
          '2': {
            title: 'Múltiples fuentes de financiamiento',
            description: 'Diferentes opciones para financiar sus cuentas en la billetera: directamente de personas, amigos, familiares, de tarjetas de crédito, débito o cuentas bancarias. Su información está encriptada y guardada sólo en su dispositivo.',
          },
          '3': {
            title: 'Recarga Fácil',
            description: '¿Necesita enviar Recarga o tiempo de Internet a la familia, amigo o empresa? ¡Puede hacerlo fácilmente con nuestra opción de Recargas!',
          },
          '4': {
            title: 'Monedas reales de Fiat: CAD, USD, EUROS u otras',
            description: 'Con unos pocos clics, convierta sus fondos a otras monedas disponibles, a tasas de cambio competitivas.',
          },
          '5': {
            title: 'Plataforma patentada de geosistemas financieros',
            description: 'Puede enviar fondos a sus amigos y familiares o facturas de pago y facturas en su área o en todo el mundo, simplemente localizando en nuestro mapa interactivo. Realice llamadas telefónicas o mediante chat, utilizando nuestra exclusiva opción de mapa de geolocalización.',
          },
        },
      },
      videoSection: {
        title: 'Una plataforma rica en funciones',
        description: 'DUC App es justo lo que estaba buscando, una aplicación nueva y líder en la industria que brinda a los usuarios la oportunidad de simplificar sus vidas con solo unos pocos clics.',
      },
      downloadSection: {
        title: 'Descargue la aplicación ahora',
        description: 'Plataforma fácil de usar con una amplia gama de características creadas con el objetivo de ayudarlo a vivir la vida al máximo. Con opciones en tiempo real y acceso ilimitado, esta es una aplicación que definitivamente querrás descargar.',
      },
      faqsSection: {
        title: 'Ayudas',
        subTitle: 'La aplicación líder en la industria. Aquí está para ayudarle',
        items: {
          '0': {
            text: 'He visto otras aplicaciones que tienen propósitos similares. ¿Qué hace que la suya sea muy útil?',
            description: 'Además de las características estándar de enviar dinero y recibir pagos inmediatos, ahora lo hará también desde nuestra opción única en el mapa. Y cuando viaja, la aplicación DUC le permite saber con anticipación y le libera de preocupaciones acerca de las tasas de cambio o los cargos adicionales cuando pague en monedas locales.',
          },
          '1': {
            text: '¿Con qué frecuencia hay actualizaciones para la aplicación?',
            description: 'Como con cualquier otra aplicación, las actualizaciones se realizan cada cierto tiempo. Algunas veces porque su propio dispositivo ha sido actualizado y en otras ocasiones, porque agregamos nuevas funciones o servicios ¡para expandir sus opciones! Y esto es transparente para usted.',
          },
          '2': {
            text: 'Todo suena genial, pero ¿qué hace realmente esta aplicación?',
            description: 'Realice y reciba pagos desde una variedad de dispositivos (teléfono, tableta, computadora portátiles y de escritorio). Convierta a múltiples monedas. Realice y solicite pagos directamente desde un mapa. Recargar teléfonos y tiempo de navegación en Internet (recargas). Comuníquese con otros a través de mensajes. Use y comparte tarjetas virtuales. Retire el dinero en la moneda de su preferencia. En todo el mundo.',
          },
        },
      },
      aboutSection: {
        appElement: {
          title: 'DUC App',
        },
        downloadElement: {
          title: 'Descarga',
          androidItem: 'Android',
          iosItem: 'iOS',
        },
        supportElement: {
          title: 'Soporte',
        },
        copyright: '© Copyright {{year}} D.U.C. App Corporation. Todos los derechos reservados.',
        terms: 'Términos de Servicios',
        privacy: 'Política de privacidad',
      },
      boardSection: {
        userElement: {
          title: 'Usuarios',
        },
        transactionsElement: {
          title: 'Transacciones',
        },
        rechargesElement: {
          title: 'Recargas',
        },
      },
    },
    webapp: {
      newVersionConfirm: 'Actual: v{{currentVersion}}\tÚltima: v{{latestVersion}}\nHay una nueva versión de esta aplicación disponible.\n¿Desea actualizar la página?',
    },
    admin: {
      toasts: {
        actions: {
          view: 'Ver',
        },
        alarms: {
          message: 'Hay nuevas alarmas ({{ alarmCount }}) marcadas como ALTA PRIORIDAD que están actualmente activas.',
        },
      },
    },
  },
};
